<template>
  <div class="container">
    <my-icon fontSize="48" class="set-icon" iconName="icon-shezhi_48_hei1" @click="gotoSettings"></my-icon>
    <img class="bg" src="../../assets/images/index_bg.png"/>
    <div class="button-area">
      <van-button type="primary" class="button join" @click="gotoJoin">加入会议</van-button>
      <van-button plain type="primary" class="button login" @click="gotoLogin">登录</van-button>
      <div class="wechat">
        <!-- <img src="../../assets/images/icon/icon_wechat.png"/> -->
      </div>
    </div>
    <p class="version">版本号：v1.1.43 </p>
    
  </div>
</template>

<script>
export default {
    data() {
     return {}
    },

   methods: {
     gotoJoin() {
      this.$router.push({
          name: 'join'
      })
     },
     gotoLogin() {
      this.$router.push({
          name: 'login'
      })
     },
     gotoSettings() {
       this.$router.push({
          name: 'meetSetting'
      })
     },


   },
}
</script>

<style scoped lang="less">
.container {
   text-align: center;
   position: relative;
   height: 100vh;

   .set-icon {
     position: absolute;
     top: 40px;
     right: 30px;
   }

   .bg {
      width: 256px;
      height: 256px;
      margin-top: 336px;
   }

   p.version {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      position: absolute;
      bottom: 72px;
      left: calc(50% - 76px);
   }

   .button-area {
      width: 600px;
      height: 370px;
      margin:0 auto;
      margin-top: 160px;
      .button {
        width: 100%;
        height: 88px;
        border-radius: 10px;
        font-size: 32px;
        font-weight: 500;
      }
      .join {
        background: #1AB370;
        color: #FFFFFF;
      }
      .login {
        background: #fff;
        color: #333333;
        border: 1px solid #E5E5E5;
        margin-top: 32px;
      }
      .wechat {
        width: 88px;
        height: 88px;
        // border: 1px solid #E5E5E5;
        border-radius: 100%;
        margin:0 auto;
        margin-top: 74px;
        display: flex;
        justify-content:center;
        align-items: center;
        img {
          width: 54px;
          height: 54px;
        }
      }

   }
}
</style>
